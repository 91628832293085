<template>
  <!-- Game Type7 (分類版塊) -->
  <div
    :id="'game-' + data.block"
    class="game-type8 my-2"
  >
    <div
      class="swiperBox d-flex"
      :style="{'height':`${swiperHeight}px`}"
    >
      <!-- 左邊tab -->
      <swiper
        id="leftSwiper"
        ref="gameItem"
        :options="tabOption"
        class="myTab"
      >
        <swiper-slide
          v-for="(item,index) in data.data.navbar"
          :key="item._id"
          style="width:auto"
        >
          <div
            class="game__item--card cursor-pointer"
            @click="getGameType(index)"
          >
            <div
              class="game-card d-flex justify-center flex-column align-center pt-1"
              :class="{ 'swiper-slide-active': tabActiveIndex === index }"
            >
              <span
                class="imgBox"
              >
                <img
                  v-if="navIcon(item)"
                  :src="baseImgUrl+navIcon(item)"
                  height="25"
                  :class="[ tabActiveIndex === index ? 'primary--text' : 'subtitle--text ManufacturerLogo']"
                >
                <Icon
                  v-else
                  :data="icons[item.class]"
                  width="25"
                  height="25"
                  :class="[ tabActiveIndex === index ? 'primary--text' : 'subtitle--text']"
                />
              </span>

              <strong
                :class="[tabActiveIndex === index ? 'primary--text' : 'subtitle--text']"
              > {{ item.game_type }}</strong>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <!-- 右邊滑動區塊 -->
      <swiper
        id="rightSwiper"
        ref="gameItem"
        :options="swiperOption"
        class="mySwiper"
      >
        <swiper-slide
          style="width:auto"
        >
          <v-row class="px-4">
            <v-col
              v-for="(item) in activeNavbarContent"
              :key="item._id"
              cols="6"
              class="game__item--card cursor-pointer d-flex justify-center flex-column align-center "
            >
              <div
                :class="item.status==='0'?'maintenanceBox':''"
                class="itemContent d-flex flex-column justify-center align-center"
                :style="gradientBg"
              >
                <!-- 維修中 父層要加 :class="item.status==='0'?'maintenanceBox':''"--> <maintenance v-if="item.status=='0'" />
                <strong class="white--text">{{ item.title }}</strong>
                <img
                  :src="baseImgUrl+item.image_h5"
                  width="100%"
                  @click="lauchGame(item.game_code, item.hyperlink,item.class)"
                >
              </div>
            </v-col>
          </v-row>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import esport from '@/assets/icons/block/game/type1/esport.svg'
import fish from '@/assets/icons/block/game/type1/fish.svg'
import live from '@/assets/icons/block/game/type1/live.svg'
import lottery from '@/assets/icons/block/game/type1/lottery.svg'
import slot from '@/assets/icons/block/game/type1/slot.svg'
import sport from '@/assets/icons/block/game/type1/sport.svg'
import card from '@/assets/icons/block/game/type1/card.svg'
import sabong from '@/assets/icons/block/game/type1/sabong.svg'
import { mapActions, mapGetters, mapState } from 'vuex'
import { goSlotPage } from '../../../../utils/slot'
import { resStatus } from '../../../../utils/resUtils'
import maintenance from '@/components/maintenance'
export default {
  components: { maintenance },
  props: {
    data: {
      type: Object,
      required: true,
    },
    // 寫死fix區塊總高度(下載app/navbar/fifaWorldCup)
    fixLayoutHeight: {
      type: Number,
      required: true,
    },
    // 需fix的動態元件總高度(banner/maraquee/
    apiFixComponentsHeight: {
      type: Number,
      required: true,
    },
    // fix的動態元件是否載入完成
    isParentRendered: {
      type: Boolean,
      required: true,
    },
    // 動態模塊數量
    layoutAreaLength: {
      type: Boolean,
      required: true,
    },

  },

  data: () => ({
    icons: {
      esport,
      fish,
      live,
      lottery,
      slot,
      sport,
      card,
      sabong,
    },
    tabOption: {
      slidesPerView: 'auto',
      centeredSlidesBounds: true,
      direction: 'vertical', // 垂直滚动
      autoHeight: true, // 高度自适应
      observer: true, // 初始化子元素
      observeParents: true, // 初始化化父元素
      pagination: { el: '.swiperOption' },
    },
    swiperOption: {
      slidesPerView: 'auto',
      centeredSlidesBounds: true,
      autoHeight: true, // 高度自适应
      observer: true,
      observeParents: true,
      effect: 'slide', // 切换效果 "coverflow"（3d流）
      grid: {
        fill: 'row',
        rows: 2,
      },

    },
    NewItemContent: [],
    NewItemContentLength: [],
    tabActiveIndex: 0,
    maintenance: false, // 維修中

  }),

  computed: {
    ...mapGetters(['baseImgUrl', 'theme', 'gradientBg']),
    ...mapState({
      isLogin: state => state.user.isLogin,
      isLiffMode: state => state.line.liff.status,
    }),
    /**
     * 取得目前allGameList
     */
    itemContents() {
      return this.data.data.navbar
    },
    /**
     * active 類別中的 vendor
     * @date 2021-09-10
     * @returns {array} 所有 vendor
     */
    activeNavbarContent() {
      return this.data.data.navbar[this.tabActiveIndex].items
    },
    /**
     * 取得allGameList6高度
     * @date 2023-04-17
     */
    swiperHeight() {
      const { apiFixComponentsHeight, fixLayoutHeight, currentDevice } = this
      const bottomNavigationHeight = (this.currentDevice === 'ios' ? 72 : 56) +
      (this.layoutAreaLength ? 50 : 10)
      return window.innerHeight - apiFixComponentsHeight - fixLayoutHeight - bottomNavigationHeight
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions([
      'get_game_url',
      'show_alert',
      'set_progress_status',
      'get_game_url_by_device',
    ]),
    /**
     * 判斷現在是亮版或暗版 決定icon
     * @date 2023-01-08
     */
    navIcon(item) {
      return this.theme === 'dark' ? item.nav_icon_dark || '' : item.nav_icon_light || ''
    },
    /**
     * 滑動至當前遊戲類型的遊戲廠商
     * @date 2024-04-10
     */
    slideTo(sumBeforeIndex) {
      // this.$refs.gameItem.$swiper.slideTo(sumBeforeIndex - 1, 1000)
    },
    /**
     * 取得當前遊戲類型＆遊戲廠商順序加總
     * @date 2024-04-10
     */
    getGameType(index) {
      // 取得當前遊戲類型
      this.tabActiveIndex = index
    },

    /**
     * 啟動遊戲 OR slot 列表頁
     * @date 2021-07-02
     * @param {string} gameCode 遊戲代碼
     * @param {string} hyperlink 遊戲代碼
     */
    lauchGame(gameCode, hyperlink, gameType) {
      // 如果尚未登入
      if (!this.isLogin) {
        return this.show_alert({
          icon: 'fail',
          text: this.$t('flashMessage.loginFirst'),
        })
      }
      // 如果為 slot fish 爾且不是遊戲清單，就到 slot 頁面
      const goSlotPageItems = ['SLOT', 'FISH']
      if (goSlotPageItems.includes(gameType)) return this.goSlotPage(hyperlink)

      console.log(`開啟遊戲 liff mode:${this.isLiffMode}`)

      const ua = navigator.userAgent.toLowerCase()
      console.log(ua)
      console.log('LINE browser: ' + ua.indexOf('line'))

      // 不為 liff 模式下 也不為 line 開啟
      if (!this.isLiffMode || ua.indexOf('line') < 0) {
        if (hyperlink === '0') return false
        // 如果Tab是遊戲類型，gameType為 live || lottery 類型，則另開視窗 游戲
        if (gameType === 'LIVE' || gameType === 'LOTTERY') return this.goBlankPage(gameCode)
      }

      // 進入嵌入遊戲頁面
      this.$router.push({
        name: 'launchGame',
        query: {
          t: gameCode,
          redirectUrl: this.$route.path,
        },
      })
    },
    /**
     * 進入遊戲列表頁面
     * @date 2021-07-02
     * @param {string} hyperlink 遊戲代碼
     */
    goSlotPage(hyperlink) {
      goSlotPage(hyperlink)
    },
    /**
     * 另開遊戲視窗 (live)
     * @date 2021-08-04
     * @param {string} gameCode 遊戲代碼
     */
    async goBlankPage(gameCode) {
      this.set_progress_status(true)
      this.getGameUrl(gameCode)
    },
    /**
     * 取得遊戲 url (成功)
     * @date 2021-07-02
     * @param {object} gameUrl 遊戲連結 url
     */
    getGameUrlSuccess(gameUrl, windowObj) {
      this.show_alert({ icon: 'done' })
      this.gameUrl = gameUrl.url
      this.set_progress_status(false)
      windowObj.location.href = gameUrl.url
    },

    /**
     * 取得遊戲 url (失敗)
     * @date 2021-07-02
     */
    getGameUrlFail() {
      this.set_progress_status(false)
      this.show_alert({ icon: 'fail' })
      this.$router.replace({ path: '/' })
    },
    /**
     * 取得遊戲 url
     * @date 2021-07-02
     * @param {string} gameCode 遊戲代碼
     */
    async getGameUrl(gameCode) {
      const windowObj = window.open('', '_blank')
      const res = await this.get_game_url_by_device({ gamecode: gameCode, is_mobile: 1 })
      resStatus(res, this.getGameUrlSuccess, this.getGameUrlFail, windowObj)
    },
  },
}
</script>

<style lang="scss" scoped>
.game-type8{
  .swiperBox{
    padding-left: 16px;
  }
  // 左邊區塊
  .myTab{
    padding: 10px 0;
    width: 15%;
    min-width: 70px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 0px 4px 0px #a0a0a085;
    background: linear-gradient(to bottom, rgb(255 255 255 / 2%) 0%, rgb(141 141 141 / 20%) 100%);
    img{
      filter: grayscale(100%);
    }
    .swiper-slide .swiper-slide-active{
      img{
        filter: brightness(90);
      }
    }
    .game-card{
      margin-bottom: 25px !important;
      strong{
        font-size: 12px;
        margin-top: 4px;
      }
    }
  }
  // 右邊區塊
  .mySwiper{
    padding: 10px 16px 0 0;
    overflow-y: auto;
    .game__item--card{
      position: relative;
      padding: 0;
      height: 37vw;
      .itemContent{
        border-radius: 20px;
        overflow: hidden;
        margin-left: 8px;
        padding-top: 10px;
        min-height: 80%;
        min-width: 95%;
      }
      strong{
        width: 100%;
        text-align: left;
        padding-left: 30px;
      }
    }
  }

  .swiper-slide{
    width: 100% !important;
  }
}

</style>
