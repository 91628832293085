var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "game-type8 my-2", attrs: { id: "game-" + _vm.data.block } },
    [
      _c(
        "div",
        {
          staticClass: "swiperBox d-flex",
          style: { height: `${_vm.swiperHeight}px` },
        },
        [
          _c(
            "swiper",
            {
              ref: "gameItem",
              staticClass: "myTab",
              attrs: { id: "leftSwiper", options: _vm.tabOption },
            },
            _vm._l(_vm.data.data.navbar, function (item, index) {
              return _c(
                "swiper-slide",
                { key: item._id, staticStyle: { width: "auto" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "game__item--card cursor-pointer",
                      on: {
                        click: function ($event) {
                          return _vm.getGameType(index)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "game-card d-flex justify-center flex-column align-center pt-1",
                          class: {
                            "swiper-slide-active": _vm.tabActiveIndex === index,
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "imgBox" },
                            [
                              _vm.navIcon(item)
                                ? _c("img", {
                                    class: [
                                      _vm.tabActiveIndex === index
                                        ? "primary--text"
                                        : "subtitle--text ManufacturerLogo",
                                    ],
                                    attrs: {
                                      src: _vm.baseImgUrl + _vm.navIcon(item),
                                      height: "25",
                                    },
                                  })
                                : _c("Icon", {
                                    class: [
                                      _vm.tabActiveIndex === index
                                        ? "primary--text"
                                        : "subtitle--text",
                                    ],
                                    attrs: {
                                      data: _vm.icons[item.class],
                                      width: "25",
                                      height: "25",
                                    },
                                  }),
                            ],
                            1
                          ),
                          _c(
                            "strong",
                            {
                              class: [
                                _vm.tabActiveIndex === index
                                  ? "primary--text"
                                  : "subtitle--text",
                              ],
                            },
                            [_vm._v(" " + _vm._s(item.game_type))]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            }),
            1
          ),
          _c(
            "swiper",
            {
              ref: "gameItem",
              staticClass: "mySwiper",
              attrs: { id: "rightSwiper", options: _vm.swiperOption },
            },
            [
              _c(
                "swiper-slide",
                { staticStyle: { width: "auto" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "px-4" },
                    _vm._l(_vm.activeNavbarContent, function (item) {
                      return _c(
                        "v-col",
                        {
                          key: item._id,
                          staticClass:
                            "game__item--card cursor-pointer d-flex justify-center flex-column align-center",
                          attrs: { cols: "6" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "itemContent d-flex flex-column justify-center align-center",
                              class:
                                item.status === "0" ? "maintenanceBox" : "",
                              style: _vm.gradientBg,
                            },
                            [
                              item.status == "0" ? _c("maintenance") : _vm._e(),
                              _c("strong", { staticClass: "white--text" }, [
                                _vm._v(_vm._s(item.title)),
                              ]),
                              _c("img", {
                                attrs: {
                                  src: _vm.baseImgUrl + item.image_h5,
                                  width: "100%",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.lauchGame(
                                      item.game_code,
                                      item.hyperlink,
                                      item.class
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }